import React, {useEffect, useState} from 'react';
import moment from 'moment';

import tickIcon from '@assets/icons/tick-circle.svg';
import slash from '@assets/icons/slash.svg';
import baggage from '@assets/icons/bag.png';
import baggageGray from '@assets/icons/BagageGray.svg';
import baggage2 from '@assets/icons/bag-2.svg';
import bag2Red from '@assets/icons/bag-2Red.svg';
import bag2Gray from '@assets/icons/bag-2Gray.svg';
import seats from '@assets/icons/SeatPossible.svg';
import bag from '@assets/icons/bag.svg';
import bag2 from '@assets/icons/bag2.svg';
import bag2Black from '@assets/icons/bag2Balck.svg';
import forwardSquareGreen from '@assets/icons/forward-square.svg';
import forwardSquareRed from '@assets/icons/forward-squareRed.svg';
import arrangeCircle from '@assets/icons/arrange-circle-2.svg';
import arrangeCircleRed from '@assets/icons/arrange-circle-2Red.svg';
import bagBlack from '@assets/icons/bagBlack.svg';
import {BsExclamationDiamond} from 'react-icons/bs';
import arrrow from '@assets/icons/arrow-rightBlack.svg';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import bagageImpossible from '@assets/icons/bagageImpossible.svg';
import bagagePossible from '@assets/icons/bagagePossible.svg';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

function TicketMap({item, inx, setTicketDetail, handleOpen, currencyFnc, accardionOpen, component, setAccardionOpen, toShoppingTicket, TicketData, allPassengrs, search}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector(state => state.AllSlice.lang);
  const currency = useSelector(state => state.AllSlice.currency);
  const priceOrder = useSelector(state => state.HomeSlice.priceOrder);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  moment.defineLocale('uz', {
    months: 'Yanvar_Fevral_Mart_Aprel_May_Iyun_Iyul_Avgust_Sentabr_Oktabr_Noyabr_Dekabr'.split('_'),
    monthsShort: 'Yan_Fev_Mar_Apr_May_Iyun_Iyul_Avg_Sen_Okt_Noy_Dek'.split('_'),
    weekdays: 'Yakshanba_Dushanba_Seshanba_Chorshanba_Payshanba_Juma_Shanba'.split('_'),
    weekdaysShort: 'Yak_Dush_Sesh_Chor_Pay_Juma_Shan'.split('_'),
    weekdaysMin: 'Ya_Du_Se_Ch_Pa_Ju_Sha'.split('_'),
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY LT',
      LLLL: 'dddd, D MMMM YYYY LT'
    },
    calendar: {
      sameDay: '[Bugun soat] LT',
      nextDay: '[Ertaga soat] LT',
      nextWeek: 'dddd [kuni soat] LT',
      lastDay: '[Kecha soat] LT',
      lastWeek: '[O‘tgan] dddd [kuni soat] LT',
      sameElse: 'L'
    },
    relativeTime: {
      future: '%s ichida',
      past: '%s oldin',
      s: 'soniyalar',
      ss: '%d soniya',
      m: 'bir daqiqa',
      mm: '%d daqiqa',
      h: 'bir soat',
      hh: '%d soat',
      d: 'bir kun',
      dd: '%d kun',
      M: 'bir oy',
      MM: '%d oy',
      y: 'bir yil',
      yy: '%d yil'
    },
    meridiemParse: /ertalab|tushdan keyin|kechasi|tong/,
    isPM: function (input) {
      return /^(tushdan keyin|kechasi)$/.test(input);
    },
    meridiem: function (hour) {
      if (hour < 12) {
        return 'ertalab';
      } else if (hour < 17) {
        return 'tushdan keyin';
      } else {
        return 'kechasi';
      }
    }
  });
  moment.locale(lang);

  const getAverageBaggageWeight = segments => {
    let smallestWeight = null;

    for (let segment of segments) {
      if (segment.baggage?.weight == null) {
        // If any baggage weight is null, return null immediately
        return null;
      }
      if (smallestWeight === null || segment.baggage.weight < smallestWeight) {
        smallestWeight = segment.baggage.weight;
      }
    }

    return smallestWeight;
  };

  const getAverageCbaggageWeight = segments => {
    let smallestWeight = null;

    for (let segment of segments) {
      if (segment.cbaggage?.weight == null) {
        // If any cbaggage weight is null, return null immediately
        return null;
      }
      if (smallestWeight === null || segment.cbaggage.weight < smallestWeight) {
        smallestWeight = segment.cbaggage.weight;
      }
    }

    return smallestWeight;
  };

  const getSmallestPiece = (segments, type) => {
    let smallestPiece = null;
    let hasZero = false;

    for (let segment of segments) {
      const piece = segment[type]?.piece;
      if (piece === 0) {
        hasZero = true;
      } else if (piece == null) {
        smallestPiece = null;
        return null;
      } else if (piece != null) {
        if (smallestPiece === null || piece < smallestPiece) {
          smallestPiece = piece;
        }
      }
    }

    if (hasZero) {
      return 0;
    } else if (smallestPiece === null) {
      return null;
    } else {
      return smallestPiece;
    }
  };

  const averageBaggagePiece = getSmallestPiece(component == 'myTicket' ? item?.response.data.book.flight.segments : item?.segments, 'baggage');
  const averageCbaggagePiece = getSmallestPiece(component == 'myTicket' ? item?.response.data.book.flight.segments : item?.segments, 'cbaggage');
  const averageBaggageWeight = getAverageBaggageWeight(component == 'myTicket' ? item?.response.data.book.flight.segments : item?.segments);
  const averageCbaggageWeight = getAverageCbaggageWeight(component == 'myTicket' ? item?.response.data.book.flight.segments : item?.segments);

  const renderBaggageInfo = (weight, piece, labels, icons) => {
    if (piece === 0) {
      return (
        <p className='flex items-center text-[14px] text-[#858585] gap-[6px]'>
          <img loading='lazy' className='w-6 h-6' src={icons.noBaggage} alt='' />
          {labels.noBaggage}
        </p>
      );
    } else if (piece == null && weight > 0) {
      return (
        <p className='flex items-center my-[5px] text-[14px] text-[#858585] gap-[6px]'>
          <img loading='lazy' className='w-6 h-6' src={icons.baggageAvailable} alt='' />
          {labels.available}: {weight}
          {t('kg')}
        </p>
      );
    } else if (piece == null && weight == null) {
      return (
        <p className='flex items-center my-[5px] text-[14px] text-[#858585] gap-[6px]'>
          <img loading='lazy' className='w-6 h-6' src={icons.noInfo} alt='' />
          {labels.noInfo}
        </p>
      );
    } else if (piece > 0 && weight == null) {
      return (
        <p className='flex items-center my-[5px] text-[14px] text-[#858585] gap-[6px]'>
          <img loading='lazy' className='w-6 h-6' src={icons.baggageAvailable} alt='' />
          {labels.available}: {piece}
        </p>
      );
    } else if (piece > 0 && weight > 0) {
      return (
        <p className='flex items-center my-[5px] text-[14px] text-[#858585] gap-[6px]'>
          <img loading='lazy' className='w-6 h-6' src={icons.baggageAvailable} alt='' />
          {labels.available}: {piece}x{weight} {t('kg')}
        </p>
      );
    }
  };

  const renderBaggageInfoSm = (weight, piece, labels, icons, type) => {
    if (piece === 0) {
      return (
        <div className='bg-no-repeat bg-contain w-6 h-6 flex items-center justify-center' style={{backgroundImage: `url(${icons.noBaggageSm})`}}>
          <p className={`text-[12px] ${type == 'baggage' ? 'mr-0' : 'mr-3'}  text-[#fff] font-medium`}>x</p>
        </div>
      );
    } else if (piece == null && weight > 0) {
      return (
        <div className='bg-no-repeat bg-contain w-6 h-6 flex items-center justify-center' style={{backgroundImage: `url(${icons.baggageAvailableSm})`}}>
          <p className={`text-[12px] ${type == 'baggage' ? 'mr-0' : 'mr-3'}  text-[#fff] font-medium`}>{`${weight}`}</p>
        </div>
      );
    } else if (piece == null && weight == null) {
      return (
        <div className='bg-no-repeat bg-contain w-6 h-6 flex items-center justify-center' style={{backgroundImage: `url(${icons.noInfo})`}}>
          <p className={`text-[12px] ${type == 'baggage' ? 'mr-0' : 'mr-3'}  text-[#fff] font-medium`}>?</p>
        </div>
      );
    } else if (piece > 0 && weight == null) {
      return (
        <div className='bg-no-repeat bg-contain w-6 h-6 flex items-center justify-center' style={{backgroundImage: `url(${icons.baggageAvailableSm})`}}>
          <p className={`text-[12px] ${type == 'baggage' ? 'mr-0' : 'mr-3'}  text-[#fff] font-medium`}>{`${piece}x`}</p>
        </div>
      );
    } else if (piece > 0 && weight > 0) {
      return (
        <div className={`bg-no-repeat bg-contain w-6 h-6  ${type == 'baggage' ? 'mt-2' : 'mt-0'} flex items-center justify-center`} style={{backgroundImage: `url(${icons.baggageAvailableSm})`}}>
          <p className={`text-[12px] ${type == 'baggage' ? 'mr-0' : 'mr-3'}  text-[#fff] font-medium`}>{`${weight}`}</p>
        </div>
      );
    }
  };

  // Baggage rendering
  const baggageLabels = {
    noBaggage: t('No baggage'),
    noInfo: t('No information'),
    available: t('bagaj')
  };
  const baggageIcons = {
    noBaggage: slash,
    noInfo: baggageGray,
    baggageAvailable: bagagePossible,
    baggageAvailableSm: bag,
    noBaggageSm: bagBlack
  };

  const cbaggageLabels = {
    noBaggage: t('No Hand luggage '),
    noInfo: t('No information'),
    available: t('Hand luggage: ')
  };
  const cbaggageIcons = {
    noBaggage: slash,
    noInfo: bag2Gray,
    baggageAvailable: baggage2,
    baggageAvailableSm: bag2,
    noBaggageSm: bag2Black
  };

  const renderSegments = segments => {
    const allChangable = segments.every(segment => segment.is_change);
    const anyNotChangable = segments.some(segment => !segment.is_change);

    return (
      <div className='flex items-center mt-1 gap-[6px] text-[14px] text-[#858585]'>
        <img className='w-6 h-6' src={allChangable ? arrangeCircle : arrangeCircleRed} alt='' />
        <p>{allChangable ? t('Reissue available') : t('No reissue')}</p>
      </div>
    );
  };
  const renderSegmentsSm = segments => {
    const allChangable = segments.every(segment => segment.is_change);
    const anyNotChangable = segments.some(segment => !segment.is_change);

    return (
      <div className='flex items-center  gap-[6px] text-[14px] text-[#858585]'>
        <img className='w-6 h-6' src={allChangable ? arrangeCircle : arrangeCircleRed} alt='' />
        {/* <p>{allChangable ? "Есть замена" : "Нет замена"}</p> */}
      </div>
    );
  };

  return (
    <Grid item lg={12} sx={{marginBottom: '20px'}}>
      <div className='box  relative w-full flex '>
        <>
          <div className='w-full md:border-r-4 pb-[12px] border-dashed  border-[#ccc] relative'>
            <div
              className='container-box md:py-5  container-box-2'
              key={inx}
              // onClick={() => window.innerWidth < 768 && handleOpen()}
            >
              <div className={`${inx === 0 && priceOrder !== 0 && 'pt-10 lg:pt-4'} ${inx === 1 && 'pt-10 lg:pt-4'} left !w-full md:pr-2`}>
                {inx === 0 && priceOrder !== 0 && (
                  <div className='absolute top-[-10px] lg:left-0 bg-[#E9F7EF] px-3 py-1 text-[#27AE60] rounded-[4px]'>
                    <p className='font-bold'>{t('Cheapest')}</p>
                  </div>
                )}
                {inx === 1 && priceOrder !== 0 && (
                  <div className='absolute top-[-10px] lg:left-0 bg-[#dcebf9] px-3 py-1 text-[#2771ae] rounded-[4px]'>
                    <p className='font-bold'>{t('Economy')}</p>
                  </div>
                )}

                {component == 'myTicket'
                  ? item?.response.data.book.flight.segments_direction
                  : item.segments_direction.map(
                      (direactionItem, direactionInx) =>
                        item.segments_direction[direactionInx] && (
                          <>
                            {direactionInx !== 0 && <span className='block my-5 w-full h-[2px] bg-[#ccc]'></span>}
                            <div className='flex items-center gap-1 mb-[8px] lg:mb-3'>
                              {/* {item.segments_direction[direactionItem].map(())} */}

                              <AvatarGroup max={5}>
                                {item.segments_direction[direactionInx].map(drxNum => (
                                  <Tooltip title={item.segments[drxNum].provider.supplier.title} placement='top'>
                                    <Avatar key={drxNum} className='!w-[24px] !h-[24px] lg:!w-[32px] lg:!h-[32px] rounded-full' src={`https://mpics.avs.io/al_square/240/240/${item.segments[drxNum].provider.supplier.code}.png`} />
                                  </Tooltip>
                                ))}
                              </AvatarGroup>
                              <div className='w-full flex items-center justify-between '>
                                <div className='flex items-center gap-[5px] lg:gap-[10px] text-[#1c1c1c] flex-wrap '>
                                  {/* <p>{item.segments[direactionInx].dep.city.title}</p> */}
                                  <>
                                    <p className='text-[13px] lg:text-[16px]'>{item.segments[item.segments_direction[direactionInx][0]].dep.city.title}</p>
                                    <img loading='lazy' className='w-[20px] hidden lg:block ' src={arrrow} alt='' />
                                    <div className='h-[5px] w-[5px] bg-[#1c1c1c] block lg:hidden rounded-full'></div>
                                  </>
                                  {/* <p>{item.segments[0].arr.city.title}</p> */}
                                  <p className='text-[13px] lg:text-[16px]'>{item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.city.title}</p>
                                </div>
                                <div className='items-center gap-[5px]  lg:gap-[8px]  flex flex-nowrap'>
                                  <p className='bg-[#3379CB] text-[13px] text-[#fff] font-semibold lg:font-normal  rounded py-[2px]  px-1 whitespace-nowrap'>
                                    {/* {item.segments[direactionInx].class.name} <span>- {t("class")}</span> */}
                                    {item.segments[direactionInx].class.name == 'B' && t('classBusiness')}
                                    {item.segments[direactionInx].class.name == 'E' && t('ekanomClass')}
                                    {item.segments[direactionInx].class.name == 'F' && t('firstClass')}
                                    {item.segments[direactionInx].class.name == 'W' && t('comfortClass')}
                                  </p>

                                  <p className='border text-[13px] text-[#2d5daf] font-semibold lg:font-normal  rounded  py-[2px]  px-1 whitespace-nowrap'>
                                    {item.segments[direactionInx].provider.supplier.code} {item.segments[direactionInx].flight_number}
                                  </p>
                                  <p className='text-[13px] font-semibold text-[#2d5daf] lg:font-normal  whitespace-nowrap'>{item.segments[direactionInx].aircraft.title.slice(0, 15)}</p>
                                </div>
                              </div>
                            </div>

                            <div className='bottom justify-between flex  lg:items-end '>
                              <div className='flex items-center justify-between  w-full gap-[10px] lg:gap-[30px]'>
                                <div className='dataL flex items-center justify-center  flex-col lg:mr-0'>
                                  <h2 className=' text-[0.675rem] text-[#1c1c1c] !font-extrabold md:!text-[33px]'>{item.segments[item.segments_direction[direactionInx][0]].dep.time}</h2>
                                  <p className=' text-[0.675rem] text-[#858585] md:mt-[-8px] md:text-[15px] font-medium w-max'>
                                    {moment(item.segments[item.segments_direction[direactionInx][0]].dep.date, 'DD.MM.YYYY').format('DD MMMM dd')}
                                  </p>
                                  <p className='text-[#858585] text-center text-[13px] mt-[-4px]'>
                                    {t('Terminal')}
                                    {item.segments[0].dep.terminal}
                                  </p>
                                </div>
                                <div className='lg:w-[66%]  sm:px-2'>
                                  <div className='map w-full justify-between '>
                                    <div className='from'>
                                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                        <g clip-path='url(#clip0_865_2363)'>
                                          <path
                                            fill-rule='evenodd'
                                            clipRule='evenodd'
                                            d='M4.29285 15.8155C4.02797 15.919 3.91945 16.2356 4.06513 16.4799L5.81319 19.4108C6.06359 19.8306 6.58081 20.0079 7.0361 19.8299L23.9381 13.223C24.7279 12.9143 25.1179 12.0237 24.8092 11.234C24.4883 10.413 23.5436 10.0302 22.7417 10.3961L17.7432 12.6773L10.773 6.27125C10.4838 6.00546 10.0685 5.9276 9.70266 6.0706C9.08963 6.31023 8.85636 7.05604 9.22358 7.60227L13.6983 14.2584L6.85554 17.3571L4.72413 15.8669C4.59802 15.7787 4.43618 15.7594 4.29285 15.8155ZM25.6776 22.9521H5.14764V24.5313H25.6776V22.9521Z'
                                            fill='#2d5daf'
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id='clip0_865_2363'>
                                            <rect width='24' height='24' rx='4' fill='white' />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <p>
                                      {Math.floor(item.segments[direactionInx].route_duration / 60)}
                                      {t('hour')}
                                      {item.segments[direactionInx].route_duration % 60}
                                      {t('min')}
                                    </p>
                                    <div className='to mr-[8px]'>
                                      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                                        <g clip-path='url(#clip0_1413_5731)'>
                                          <path
                                            fill-rule='evenodd'
                                            clipRule='evenodd'
                                            d='M6.84152 9.47881C6.57104 9.39093 6.28638 9.56695 6.24415 9.84821L5.73752 13.223C5.66495 13.7064 5.95251 14.1715 6.41742 14.3225L23.6767 19.9304C24.4832 20.1924 25.3494 19.7511 25.6114 18.9446C25.8838 18.1063 25.396 17.2113 24.5439 16.9858L19.2322 15.5804L17.9041 6.20726C17.849 5.81835 17.5772 5.4948 17.2037 5.37342C16.5777 5.17003 15.9244 5.59884 15.862 6.25407L15.1019 14.2384L7.84571 12.2958L7.14239 9.79207C7.10078 9.64392 6.98787 9.52637 6.84152 9.47881ZM25.6776 22.9521H5.14758V24.5313H25.6776V22.9521Z'
                                            fill='#2d5daf'
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id='clip0_1413_5731'>
                                            <rect width='24' height='24' rx='4' fill='white' />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                  </div>

                                  <div className='flex items-center justify-between w-full mt-3'>
                                    <div className='flex flex-col relative'>
                                      <div className='peer'>
                                        <span className={`block -translate-y-[4px] rounded-[1px]  bg-[#3379CB]  w-6 h-[5px]`}></span>
                                        <p className='w-min mt-2'> {item.segments[item.segments_direction[direactionInx][0]].dep.airport.code}</p>
                                      </div>
                                      <div className='absolute peer-hover:!block hidden !text-[12px] lg:!text-base text-center text-white !bg-[rgba(12,19,29,0.85)] rounded-lg !h-auto bottom-[50px] w-[150px] lg:!w-[300px] left-[-50%] p-1'>
                                        {lang == 'uz' && (
                                          <p>
                                            {item.segments[item.segments_direction[direactionInx][0]].dep.city.title}({item.segments[item.segments_direction[direactionInx][0]].dep.airport.title}) dan mahalliy vaqt bo'yicha {item.segments[item.segments_direction[direactionInx][0]].dep.time} da jo'nab
                                            ketish
                                          </p>
                                        )}
                                        {lang == 'ru' && (
                                          <p>
                                            Вылет из {item.segments[item.segments_direction[direactionInx][0]].dep.city.title}({item.segments[item.segments_direction[direactionInx][0]].dep.airport.title}) по местному времени в {item.segments[item.segments_direction[direactionInx][0]].dep.time}
                                          </p>
                                        )}
                                        {lang == 'en' && (
                                          <p>
                                            Departure from {item.segments[item.segments_direction[direactionInx][0]].dep.city.title}({item.segments[item.segments_direction[direactionInx][0]].dep.airport.title}) at {item.segments[item.segments_direction[direactionInx][0]].dep.time} local time
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className='w-full relative '>
                                      <div className='py-[20px] flex items-center justify-center peer mb-[38px]'>
                                        <div className={`${item.segments_direction[0].length == 1 ? 'w-[90px]' : item.segments_direction[0].length > 2 ? 'w-[10px]' : 'w-[21px]'}  md:w-[40px] lg:w-full  bg-[gray] rounded-lg h-[2px]`}></div>
                                      </div>
                                      {item.segments_direction[0].length > 0 && (
                                        <div className='absolute peer-hover:!block hidden !text-[12px] lg:!text-base lg:text-md text-center text-white !bg-[rgba(12,19,29,0.85)] rounded-lg !h-auto bottom-[70px] w-[150px] lg:!w-[300px] left-[0] p-1'>
                                          <p>
                                            {t('On the way: ')}
                                            {item.segments[item.segments_direction[direactionInx][0]].duration.flight.hour}
                                            {t('hour')}
                                            {item.segments[item.segments_direction[direactionInx][0]].duration.flight.minute}
                                            {t('min')}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                    {direactionItem.map(drxInx =>
                                      direactionItem.some(elem => elem === drxInx + 1) && item.segments[drxInx + 1] && item.segments[drxInx + 1]?.dep.city.code === item.segments[drxInx]?.arr.city.code ? (
                                        <>
                                          <div className='relative'>
                                            <div className='peer'>
                                              <div className='flex justify-center'>
                                                <span className={`block rounded-[1px] -translate-y-[4px] ${item.segments_direction[0].length > 2 ? 'mx-[3px]' : 'mx-[6px]'}  bg-[#3379CB] h-[5px] ${item.segments_direction[0].length > 2 ? 'w-4' : 'w-6'}`}></span>
                                              </div>
                                              <div className='flex justify-center !bg-transparent'>
                                                <p className='w-min mt-2'>{item.segments[drxInx].arr.airport.code}</p>
                                              </div>
                                            </div>
                                            {item.segments[drxInx].duration?.transfer && (
                                              <div className='absolute peer-hover:block text-white !text-[12px] lg:!text-base bg-[rgba(12,19,29,0.85)] rounded-lg hidden h-auto bottom-[50px] w-[150px] lg:w-[300px] left-[-100px] p-1 text-center'>
                                                {lang == 'uz' && (
                                                  <>
                                                    <p>
                                                      {item.segments[drxInx].arr.city.title}({item.segments[drxInx].arr.airport.title}) da {item.segments[drxInx].duration?.transfer.hour}
                                                      {t('hour')}
                                                      {item.segments[drxInx].duration?.transfer.minute}
                                                      {t('min')} to'xtab uchish
                                                    </p>
                                                    <p>Mahalliy vaqt bo'yicha {item.segments[drxInx + 1].dep.time} da jo'nab ketish</p>
                                                  </>
                                                )}
                                                {lang == 'ru' && (
                                                  <>
                                                    <p>
                                                      Пересадка в {item.segments[drxInx].arr.city.title}({item.segments[drxInx].arr.airport.title}) {item.segments[drxInx].duration?.transfer.hour}
                                                      {t('hour')}
                                                      {item.segments[drxInx].duration?.transfer.minute}
                                                      {t('min')}
                                                    </p>
                                                    <p>Вылет по местному времени в {item.segments[drxInx + 1].dep.time}</p>
                                                  </>
                                                )}
                                                {lang == 'en' && (
                                                  <>
                                                    <p>
                                                      Layover at {item.segments[drxInx].arr.city.title}({item.segments[drxInx].arr.airport.title}) for {item.segments[drxInx].duration?.transfer.hour}
                                                      {t('hour')}
                                                      {item.segments[drxInx].duration?.transfer.minute}
                                                      {t('min')}
                                                    </p>
                                                    <p>Departure at {item.segments[drxInx + 1].dep.time} local time</p>
                                                  </>
                                                )}
                                              </div>
                                            )}
                                          </div>

                                          <div className='w-full relative'>
                                            <div className='peer mb-[38px] py-[12px]'>
                                              <div className={`${item.segments_direction[0].length > 2 ? 'w-[10px]' : 'w-[21px]'} md:w-[40px] lg:w-full   lg:mr-[0]  bg-[gray] h-[2px]`}></div>
                                            </div>
                                            <div className='absolute peer-hover:!block hidden text-center !text-[12px] lg:!text-base text-white !bg-[rgba(12,19,29,0.85)]  rounded-lg !h-auto bottom-[70px] w-[150px] lg:!w-[300px] left-[-90%] p-1'>
                                              <p>
                                                {t('On the way: ')}
                                                {item.segments[drxInx + 1].duration.flight.hour}
                                                {t('hour')}
                                                {item.segments[drxInx + 1].duration.flight.minute}
                                                {t('min')}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className='!w-auto'></div>
                                      )
                                    )}
                                    <div className='relative  '>
                                      <div className='peer'>
                                        <div className='flex justify-end '>
                                          <span className='!block  rounded-[1px] -translate-y-[4px] bg-[#3379CB] w-6 h-[5px]'></span>
                                        </div>
                                        <div className='flex justify-end !bg-transparent '>
                                          <p className='w-min mt-2'>{item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.airport.code}</p>
                                        </div>
                                      </div>
                                      <div className='absolute text-center peer-hover:!block hidden !text-[12px] lg:!text-base text-white !bg-[rgba(12,19,29,0.85)] rounded-lg !h-auto bottom-[50px] w-[150px] lg:!w-[300px]  left-[-50%] p-1'>
                                        {lang == 'uz' && (
                                          <p>
                                            {item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.city.title}({item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.airport.title}) ga
                                            mahalliy vaqt bo'yicha {item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.time} da uchib kelish
                                          </p>
                                        )}
                                        {lang == 'ru' && (
                                          <p>
                                            Прилёт в {item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.city.title}({item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.airport.title})
                                            по местному времени в {item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.time}
                                          </p>
                                        )}
                                        {lang == 'en' && (
                                          <p>
                                            Arrival at {item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.city.title}(
                                            {item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.airport.title}) at {item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.time} local time
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='dataR flex items-center justify-center  flex-col'>
                                  <h2 className='text-[0.675rem] text-[#1c1c1c] !font-extrabold md:!text-[33px]'>{item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.time}</h2>
                                  <p className=' text-[0.675rem] ml-[1px] text-[#858585]  md:mt-[-8px] font-medium md:text-[15px] w-max'>
                                    {moment(item.segments[item.segments_direction[direactionInx][item.segments_direction[direactionInx].length - 1]].arr.date, 'DD.MM.YYYY').format('DD MMMM dd')}
                                  </p>
                                  <p className='text-[#858585] text-center text-[13px] mt-[-4px]'>
                                    {t('Terminal')}
                                    {item.segments[0].arr.terminal}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                    )}

                <div className='top mt-[8px] flex items-center justify-between'>
                  <h2 className='flex lg:mb-[0] w-full justify-between items-center'>
                    <p>
                      <span className='hidden w-max text-[#2d5daf] sum'>
                        {item.price && currency === 'UZS' ? item.price.UZS?.amount : currency === 'RUB' ? item.price.RUB?.amount : currency === 'USD' && item.price.USD?.amount}
                        <span className='font-normal ml-[5px] text-[15px] text-[#5489e3]'>{currency?.toLowerCase()}</span>
                      </span>
                    </p>
                    <div className='flex items-center gap-[8px] lg:hidden'>
                      {renderBaggageInfoSm(averageBaggageWeight, averageBaggagePiece, baggageLabels, baggageIcons, 'baggage')}

                      <div className='flex items-center gap-[6px]'>
                        <img className='w-6 h-6' src={item.is_refund ? forwardSquareGreen : forwardSquareRed} alt='' />
                      </div>

                      {/* <div className="flex items-center gap-[6px]">
                        <img
                          className="w-6 h-6"
                          src={item.segments[0].is_change ? arrangeCircle : arrangeCircleRed}
                          alt=""
                        />
                      </div> */}
                      {renderSegmentsSm(item.segments)}

                      {renderBaggageInfoSm(averageCbaggageWeight, averageCbaggagePiece, cbaggageLabels, cbaggageIcons, 'cbaggage')}
                    </div>
                  </h2>
                </div>
              </div>
            </div>

            <span className='hidden md:block absolute top-0 h-3 w-5 bg-[#E8E8E8] right-[-0.747rem] rounded-b-lg'></span>
            <span className='hidden md:block absolute bottom-0 h-3 w-5 bg-[#E8E8E8] right-[-0.747rem] rounded-t-lg'></span>
            <div className='flex items-center justify-center w-full'>
              <button className='border-2 whitespace-nowrap block lg:hidden mb-[5px] !min-w-[200px] border-[#27AE60] hover:bg-transparent hover:text-[#27AE60] bg-[#27AE60]  rounded-[8px] py-[8px] px-[10px] text-[#fff] mt-2  ' onClick={() => toShoppingTicket(item.id)}>
                {t('buy')}
              </button>
            </div>
          </div>

          <div className='right  px-3 py-2 md:py-2'>
            <h2 className='text-3xl w-max font-bold text-[#2d5daf]'>
              {item.price && currency === 'UZS' ? item.price.UZS?.amount : currency === 'RUB' ? item.price.RUB?.amount : currency === 'USD' && item.price.USD?.amount}
              <span className='font-normal text-[20px] text-[#5489e3]'>{currency?.toLowerCase()}</span>
            </h2>

            {search.adt + search.chd + search.yth > 1 && <p className='text-[13px] mb-[12px]'>{t('For all passengers')}</p>}
            <p className='text-[#858585] text-[14px] flex items-center gap-[7px]'>
              {' '}
              <img loading='lazy' className=' w-6 h-6' src={seats} alt='' />
              {t('Have a seats: ')}
              {component == 'myTicket' ? item?.response.data.book.flight.segments[0].seats : item.segments[0].seats}
            </p>
            {renderBaggageInfo(averageCbaggageWeight, averageCbaggagePiece, cbaggageLabels, cbaggageIcons)}

            {item.is_refund == true ? (
              <div className='flex items-center mb-1 gap-[6px] text-[14px] text-[#858585]'>
                <img className='w-6 h-6' src={forwardSquareGreen} alt='' />
                <p>{t('Refund available')}</p>
              </div>
            ) : (
              <div className='flex items-center mb-1 gap-[6px] text-[14px] text-[#858585]'>
                <img className='w-6 h-6' src={forwardSquareRed} alt='' />
                <p>{t('No refund')}</p>
              </div>
            )}

            {renderBaggageInfo(averageBaggageWeight, averageBaggagePiece, baggageLabels, baggageIcons)}
            {renderSegments(item.segments)}

            <button className='border-2  whitespace-nowrap mb-[5px] !min-w-[200px] border-[#27AE60] hover:bg-transparent hover:text-[#27AE60] bg-[#27AE60]  rounded-[8px] py-[8px] px-[10px] text-[#fff] mt-2  ' onClick={() => toShoppingTicket(item.id)}>
              {t('buy')}
            </button>
          </div>
        </>
      </div>
    </Grid>
  );
}

export default TicketMap;
