import React, { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setActiveIndex } from '@Slice/HomeSlice';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import TicketJd from '../ticketJd';
import Railways from '../../components/Main/Railways';

function SearchJd() {
    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setActiveIndex(1))
    }, [])

    const parts = params.id?.split("&") || '';

    useEffect(() => {
        params.id && localStorage.setItem('searchJd', params.id)
    }, [params])

    return (
        <div>
            <Helmet>
                <title> My Safar - Search </title>
                <meta name="description" content="Explore the Search Page of My Safar App" />
            </Helmet>

            <div className="container mx-auto">
                <Railways version={"inCanvas"} fromRailwaysName={parts[0]} fromRailways={parts[1]} toRailwaysName={parts[2]} toRailways={parts[3]} date={parts[5]} dateTo={parts[6]} />
            </div>
            <TicketJd fromRailwaysName={parts[0]} fromRailways={parts[1]} toRailwaysName={parts[2]} toRailways={parts[3]} date={parts[5]} />
            <div id='show_moreEl'></div>
        </div>
    )
}

export default SearchJd