import React, {useEffect, useState, Suspense} from 'react';

import {Routes, Route, useLocation, Navigate} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/uz';
import ProtectedRoutes from './ProtectedRoutes/ProtectedRoutes';
import {useGetContentsMutation, useSwitchUpDownMutation} from '@services/AllApi';
import {useSelector} from 'react-redux';
import Loader from '@components/Loader/Loader';
import {useTranslation} from 'react-i18next';
import TechnicalError from '@components/ui/TechnicalError';
import Search from '@pages/search';
import TicketJd from '@pages/ticketJd';
import SearchJd from '@pages/search/SearchJd';
import SearchTabs from '@pages/search/SearchTabs';
import Contents from '@pages/contents';

// Lazy load components
const Navbar = React.lazy(() => import('@components/Navbar/Navbar'));
const Footer = React.lazy(() => import('@components/Footer/Footer'));
const Home = React.lazy(() => import('@pages/Home'));
const Profile = React.lazy(() => import('@pages/profile'));
const ShopTicket = React.lazy(() => import('@components/ShopTicket/ShopTicket'));
const BasicModal = React.lazy(() => import('@components/Modal/Modal'));
const UzCardPayment = React.lazy(() => import('@pages/Uz-card-payment'));
const AboutUs = React.lazy(() => import('@pages/aboutUs'));
const Sale = React.lazy(() => import('@pages/Sale'));
const Faq = React.lazy(() => import('@pages/faq'));
const GetTicket = React.lazy(() => import('@components/ShopTicket/GetTicket'));

function App() {
  const lang = useSelector(state => state.AllSlice.lang);
  const loggedIn = useSelector(state => state.LoginSlice.loggedIn);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [switchUpDown, {data: switchUpDownData, isSuccess: switchUpDownSuc}] = useSwitchUpDownMutation();
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const hideNavbarAndFooter = location.pathname.startsWith('/payment');
  const isSearchRoutes = location.pathname.startsWith('/search');
  const [getContents, {data: getContentsData, isSuccess: getContentsSuc}] = useGetContentsMutation();

  moment.locale(lang);

  useEffect(() => {
    switchUpDown();
  }, []);

  useEffect(() => {
    getContents();
  }, []);

  useEffect(() => {
    if ((window.location.pathname !== '/search/') && (window.location.pathname !== '/searchJd/')) {
      ['search', 'searchJd'].forEach(key => localStorage.removeItem(key));
    }
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem('lang')
    if(!lang) {
        localStorage.setItem('lang', 'ru')
    }
}, []) /// create default lang in storage

  useEffect(() => {
    if (switchUpDownSuc) {
      setIsMaintenanceMode(switchUpDownData.value === '0');
    }
  }, [switchUpDownSuc, switchUpDownData]);

  if (isMaintenanceMode) {
    return <TechnicalError />;
  }

  return (
    <Suspense fallback={<Loader />}>
      {!hideNavbarAndFooter || !loggedIn ? <Navbar /> : ''}
      {isSearchRoutes && windowWidth > 1024 && <SearchTabs />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/search/:id?' element={<Search />} />
        <Route path='/searchJd/:id?' element={<SearchJd />} />
        <Route path='/aboutUs' element={<AboutUs />} />
        <Route path='/sale' element={<Sale />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/order/:id' element={<ShopTicket />} />
        <Route path='/getTicket/:id' element={<GetTicket />} />
        <Route path='/payment/:id/:id' element={<UzCardPayment />} />
        <Route path={`/blog/:id`} element={<Contents getContentsData={getContentsData} />} />
        <Route element={<ProtectedRoutes />}>
          <Route path='/profile' element={<Profile />} />
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
      <BasicModal />
      {!hideNavbarAndFooter && <Footer />}
    </Suspense>
  );
}

export default App;
